import styled from 'styled-components';
import HoverImage from '../../images/homeBottom-item-bg.gif';

export const MainContainer = styled.div`
  background: #fff;
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const InnerContainer = styled.div`
  width: 1200px;
  margin: auto;
  margin-top: -27px;
  position: relative;
  z-index: 999;
  @media (max-width: 1199px) {
    width: 960px;
  }
  @media (max-width: 991px) {
    width: 768px;
    margin-top: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding-top: 45px !important;
  }
`;

export const AnchorStyle = styled.a`
  display: block;
  float: left;
  width: 25%;
  padding: 45px 25px 25px 25px;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #888;
  text-align: left;
  text-decoration: none;
  :hover {
    text-decoration: none;
    background: url(${HoverImage}) repeat-x bottom left;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
  }
  @media (max-width: 1199px) {
    padding: 45px 39px 25px 39px;
  }
  @media (max-width: 991px) {
    padding: 45px 25px 25px 25px;
  }
  @media (max-width: 767px) {
    display: block;
    float: none;
    width: 100%;
    padding: 0 30px 30px 30px;
    font-family: 'Gotham-Book', arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #888;
  }
`;

export const Header2 = styled.h2`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 26px;
  line-height: 30px;
  color: ${(props) => (props.type === 'retail' ? '#0069aa' : '#008752')};
  padding-top: 5px;
  margin: 0 0 35px 0;
  padding: 0;
  @media (max-width: 767px) {
    text-align: center;
  }
  @media (max-width: 1199px) {
    text-align: center;
  }
  @media (max-width: 991px) {
    text-align: center;
  }
`;

export const ImageStyle = styled.img`
  float: right;
  width: 72px;
  height: auto;
  border: 0;
  vertical-align: top;
  margin-left: 15px;
  @media (max-width: 1199px) {
    display: block;
    float: none !important;
    width: auto !important;
    height: 100px !important;
    margin: 0 auto 35px auto !important;
  }
`;
