import * as React from 'react';
import * as Styled from './homeBottomStyles';
import { Row, Col } from 'react-bootstrap';
import isExternalUrl, {
  isExternalUrlhref,
  addTrailingSlash,
} from '../../utils';

const HomeBottom = (props) => {
  return (
    <>
      <Styled.MainContainer>
        <Styled.InnerContainer className="p-0">
          <Row className="g-0">
            <Col xs={12} md>
              {props?.sectionData?.listOfImagesWithPath?.map((item, index) => {
                return (
                  <Styled.AnchorStyle
                    key={index}
                    href={
                      isExternalUrlhref(item?.path)
                        ? item?.path
                        : addTrailingSlash(
                            process.env.GATSBY_DOMAIN_URL + item?.path
                          )
                    }
                    target={isExternalUrl(item?.path ? '_blank' : '_self')}
                  >
                    <Styled.Header2 type={item?.type}>
                      <Styled.ImageStyle
                        src={item?.image?.file?.url}
                        alt="home-bottom-images"
                      />
                      {item?.title}
                    </Styled.Header2>
                  </Styled.AnchorStyle>
                );
              })}
            </Col>
          </Row>
        </Styled.InnerContainer>
      </Styled.MainContainer>
      <div style={{ clear: 'both' }} />
    </>
  );
};

export default HomeBottom;
